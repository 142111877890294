.space-micro {
    padding-top: $space_micro;
    padding-bottom: $space_micro;
}
.space-top-micro {
    padding-top: $space_micro;
}
.space-bottom-micro {
    padding-bottom: $space_micro;
}

.space-small {
    padding-top: $space_small;
    padding-bottom: $space_small;
}
.space-top-small {
    padding-top: $space_small;
}
.space-bottom-small {
    padding-bottom: $space_small;
}

.space-default {
    padding-top: $space_default;
    padding-bottom: $space_default;
}
.space-top-default {
    padding-top: $space_default;
}
.space-bottom-default {
    padding-bottom: $space_default;
}


section {
    padding-top: $space_default;
    padding-bottom: $space_default;
}