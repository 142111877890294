.background-dark {
    background-color: $color_dark;
    color: $color_light;
}
.background-light {
    background-color: $color_light;
    color: $color_dark;
}
.background-yellow {
    background-color: $color_yellow;
    color: $color_dark;
}