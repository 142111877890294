#hosts {

    [class^="col-"] {
        @include media-breakpoint-down(sm) {
            margin-bottom: $space_small;
        }
    }
    
    .card {
        height: 100%;
        border-radius: 10px;
        overflow: hidden;

        .card-body {
            padding: 1.5rem 2.25rem;

            .name {
                color: $color_yellow;
                text-transform: uppercase;
            }
        }
    }
}