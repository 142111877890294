@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');


@font-face {
    font-family: 'PF DinDisplay Pro';
    src: url('../font/PFDinDisplayPro/PFDinDisplayPro-Regular.eot');
    src: url('../font/PFDinDisplayPro/PFDinDisplayPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('../font/PFDinDisplayPro/PFDinDisplayPro-Regular.woff2') format('woff2'),
        url('../font/PFDinDisplayPro/PFDinDisplayPro-Regular.woff') format('woff'),
        url('../font/PFDinDisplayPro/PFDinDisplayPro-Regular.ttf') format('truetype'),
        url('../font/PFDinDisplayPro/PFDinDisplayPro-Regular.svg#PFDinDisplayPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PF DinDisplay Pro';
    src: url('../font/PFDinDisplayPro/PFDinDisplayPro-Bold.eot');
    src: url('../font/PFDinDisplayPro/PFDinDisplayPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../font/PFDinDisplayPro/PFDinDisplayPro-Bold.woff2') format('woff2'),
        url('../font/PFDinDisplayPro/PFDinDisplayPro-Bold.woff') format('woff'),
        url('../font/PFDinDisplayPro/PFDinDisplayPro-Bold.ttf') format('truetype'),
        url('../font/PFDinDisplayPro/PFDinDisplayPro-Bold.svg#PFDinDisplayPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PF DinDisplay Pro';
    src: url('../font/PFDinDisplayPro/PFDinDisplayPro-Medium.eot');
    src: url('../font/PFDinDisplayPro/PFDinDisplayPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('../font/PFDinDisplayPro/PFDinDisplayPro-Medium.woff2') format('woff2'),
        url('../font/PFDinDisplayPro/PFDinDisplayPro-Medium.woff') format('woff'),
        url('../font/PFDinDisplayPro/PFDinDisplayPro-Medium.ttf') format('truetype'),
        url('../font/PFDinDisplayPro/PFDinDisplayPro-Medium.svg#PFDinDisplayPro-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

