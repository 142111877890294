#available-dates {
    background-image: url('../img/background-sparks.jpg');
    background-position: center;
    background-size: cover;

    .title {
        color: $color_yellow;
    }

    .row-dates {

        [class^="col-"] {
            @include media-breakpoint-down(sm) {
                margin-bottom: $space_small;
            }
        }
        
        .card {
            border: none;
            border-radius: 10px;
            overflow: hidden;
            height: 100%;
    
            .card-header {
                padding: $space_small;
                border-bottom: none;
    
                > * {
                    margin-bottom: 0;
                }
    
                h3 {
                    margin-top: 5px;
                }
            }
    
            .card-body {
    
                .location {
                    margin-bottom: $space_micro;
                }
            }
        }
    }
}