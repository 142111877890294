html, body {
    background-color: $color_dark;
    -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    font-family: $font_body;
}

.btn {
    font-size: 15px;
    text-transform: uppercase;
    border-radius: 0;
    font-weight: bold;
    letter-spacing: 3px;
    padding: 0.5rem 1rem;
}