#hero {
    position: relative;
    background-size: cover;
    background-position: center;
    @include media-breakpoint-down(sm) {
        background-image: url('../img/hero-mobile.jpg');
        padding: $space_small;
        min-height: 600px;
    }
    @include media-breakpoint-up(md) {
        background-image: url('../img/hero-desktop.jpg');
        padding: 13vw 0;
    }

    h1 {
        margin-bottom: 0;
    }

    .title {
        max-width: 525px;
        width: 100%;
        height: auto;
    }

    .subtitle {
        font-family: $font_title;
        color: $color_yellow;
        text-transform: uppercase;
        font-weight: normal;
        @include media-breakpoint-down(sm) {
            font-size: 19px;
            letter-spacing: 2px;
        }
        @include media-breakpoint-up(md) {
            font-size: 22px;
            letter-spacing: 4px;
        }
    }

    .btn {
        position: absolute;
        z-index: 99;
        transform: translateX(-50%);
    }

    #particles-js {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 0;
    }
}