#footer {

    .sponsors{
        
        .sponsor {
            display: inline-flex;
            margin: calc($space_micro / 1.5);
            
            img {
                display: block;
                max-width: 100px;
                max-height: 40px;
                width: auto;
                height: auto;
            }
        }
    }
}