#media-enquiries {

    form {

        .form-group {

            .form-control {
                border-color: transparent;
                
                &.error {
                    border-color: $color_dark;
                }
            }

            span.error {
                font-size: 0.85rem;
            }
        }
    }

    #enquiry-output-message {

        .fa {
            font-size: 36px;
        }
    }
}