h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    text-transform: uppercase;
    font-family: $font_title;
}

h2 {
    font-size: 25px;
}

h3 {
    font-size: 25px;
}

p {

    &.highlight {
        font-size: 20px;
        font-weight: bold;
    }
}

sup {
    font-size: 60%;
}